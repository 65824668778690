import styled from "styled-components";
import QRCode from "qrcode";
import { FaTicketAlt } from "react-icons/fa";
import { MdPhotoCamera } from "react-icons/md";
import { jsPDF } from "jspdf";
import { Link } from "react-router-dom";
import ModalPergunta from "../../../componentes/ModalPergunta";
import { deletarTicket } from "../../../servicos/ticketsServices";
import { BotaoExcluir } from "../AdminStyled";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
const TituloColunas = styled.th`
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: 30px;
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};

    .Icone {
        margin: 5px 5px 0 0;
        padding: 0;
        cursor: pointer;
    }
`
export function TabelaTicketsVisitante(tickets, showAviso, setShowAviso) {
    const campos = [
        {chave: "numero", label: "Número"},
        {chave: "tipo", label: "Tipo"},
        {chave: "sessao", label: "Sessão"}
    ];

    async function imprimirTicket(numero, tipo, sessao){
        try {
            const doc = new jsPDF({
                orientation: "landscape",
                unit: "mm",
                format: [90, 50.8]
            });
            doc.setFont(undefined, "bold");
            let pos = 0;
            if (tipo === "PROMOCIONAL") pos = 38; else 
            if (tipo === "CORTESIA") pos = 35; else pos = 33;
            doc.setFontSize(9)
                .text("ACESSO ÚNICO E INDIVIDUAL", 12, 49, null, 90)
                .text(tipo, 17, pos, null, 90);
            doc.setFontSize(8)
                .text("NÃO REEMBOLSÁVEL", 23, 41, null, 90)
                .text("O não comparecimento na sessão", 61, 49, null, 90)
                .text("implica na perda do ingresso", 65, 46, null, 90);
            doc.setFontSize(18).text(sessao.diaHora.split("T")[1].slice(0,5), 75, 35, null, 90);
            doc.setFontSize(12).text(`${new Date(sessao.diaHora).toLocaleDateString('en-GB')}`, 82, 37, null, 90);
            let qrcode = await QRCode.toDataURL(numero.toString());
            doc.addImage(qrcode, 'png', 28, 13, 27, 27);
            
            let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = doc.output("bloburl");
            iframe.onload = () => {
                iframe.contentWindow.print();
            };
        } catch (e) {
            console.log(e.message);
        }
    }
    
    const handleSim = async () => {
        await deletarTicket(showAviso.ticket);
        setShowAviso({ mostrar: false, ticket: null });
    };
    const handleNao = async () => {
        setShowAviso({ mostrar: false, ticket: null });
    };
    
    try {
        return (
            <>{ModalPergunta("Tem certeza que deseja deletar este ingresso?", handleSim, handleNao, showAviso.mostrar, "100px")}
            <Tabela>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave}>
                                <p>{campo.label}</p>
                            </TituloColunas>
                        })}
                        <th style={{color: "#252525"}}>Ações</th>
                    </LinhaTabela>
                </thead>
                <tbody>

                    {tickets.map((ticket) => {
                        return (<LinhaTabela key={ticket._id}> 
                            <ColunaTabela><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/ingressos/${ticket._id}`}>{ticket.numero}</Link></ColunaTabela>
                            <ColunaTabela>{ticket.tipo}</ColunaTabela>
                            <ColunaTabela><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/sessoes/${ticket.sessao._id}`}>{new Date(ticket.sessao.diaHora).toLocaleDateString('en-GB')} {ticket.sessao.diaHora.split("T")[1].slice(0,5)}</Link></ColunaTabela>
                            <ColunaTabela>
                                <FaTicketAlt className="Icone" onClick={() => imprimirTicket(ticket.numero, ticket.tipo, ticket.sessao)}/>
                                {ticket.foto && <Link style={{ textDecoration: 'none', color: "inherit" }} target={"_blank"} to={`/foto/${ticket.foto}`}><MdPhotoCamera className="Icone"/></Link>}
                                <BotaoExcluir onClick={() => setShowAviso({ mostrar: true, ticket: ticket.numero })}>Excluir</BotaoExcluir>
                            </ColunaTabela>
                        </LinhaTabela>)
                    })}
                </tbody>
            </Tabela>
            </>
        )
    } catch (e) {
        return <></>
    }
}