import Cookies from "js-cookie";
import { getGuiaPorCodigo } from "./guiasServices.js";

async function comissoesLojas(dataI, dataF){
    try {
        let dia = new Date(dataI + "T00:00:00-03:00");
        const fim = new Date(dataF + "T00:00:00-03:00");
        let respostas = [];
        let comissionadas = [];

        while (dia <= fim) {
            respostas.push(fetch(`/api/chef/comissoes/${dia.toISOString().split("T")[0]}`, {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("token_tour")
                }
            }).then( resposta => resposta.json()).then( resposta => comissionadas.push(...resposta)));
            dia.setDate(dia.getDate() + 1);
        }
        await Promise.all(respostas);
        let comissoesChef = comissionadas
            .filter((venda, indice) => comissionadas.findIndex(e => e.guia.codigo === venda.guia.codigo) === indice)
            .map(venda => {
                return {
                    guia: venda.guia,
                    atendimentosTour: 0,
                    atendimentosCentro: 0,
                    comissaoLojaTour: 0,
                    comissaoLojaCentro: 0
                }
            });
        comissionadas.forEach(venda => {
            let i = comissoesChef.findIndex(e => e.guia.codigo === venda.guia.codigo);
            if (venda.loja === 1) {
                comissoesChef[i].comissaoLojaTour += venda.valor * 0.05;
                comissoesChef[i].atendimentosTour++;
            } else {
                comissoesChef[i].comissaoLojaCentro += venda.valor * 0.05;
                comissoesChef[i].atendimentosCentro++;
            }
        });
        // comissoesChef[comissoesChef.findIndex(e => e.guia.codigo === 480)].comissaoLojaTour -= 30;      // Remoção de valor pago adiantado
        return comissoesChef;
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

async function comissoesTour(dataI, dataF){
    try {
        const resposta = await fetch(`/api/tickets/comissionados?dataInicial=${dataI}&dataFinal=${dataF}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        if (resposta.status !== 200) return [];
        const tickets = (await resposta.json()).tickets;
        let comissoes = tickets
            .filter( (ticket, index) => tickets.findIndex(e => e.guia.codigo === ticket.guia.codigo) === index)
            .map( ticket => {
                return {
                    guia: {
                        codigo: ticket.guia.codigo,
                        nome: ticket.guia.nome
                    },
                    comissaoTour: 0
                }
            });
        tickets.forEach( ticket => {
            let i = comissoes.findIndex(e => e.guia.codigo === ticket.guia.codigo);
            if (ticket.tipo === "PROMOCIONAL-ONLINE" || ticket.tipo === "PROMOCIONAL" || ticket.tipo === "PROMOCIONAL-EXCURSAO" || ticket.tipo === "PROMOCIONAL-EXCURSÃO") {
                comissoes[i].comissaoTour += 5;
            }
        });
        // comissoes[comissoes.findIndex(e => e.guia.codigo === 480)].comissaoTour -= 45;      // Remoção de valor pago adiantado
        return comissoes.filter( com => com.comissaoTour > 0);
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function getComissoes(dataI, dataF){
    try {
        const comissoesPromessas = [comissoesLojas(dataI, dataF), comissoesTour(dataI, dataF)];
        const dados = await Promise.all(comissoesPromessas);
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        const data = new Date(Date.now());
        const dataHora = zeroPad(data.getDate(), 2) + zeroPad(data.getMonth() + 1, 2) + data.getFullYear() + zeroPad(data.getHours(), 2) + zeroPad(data.getMinutes(), 2) + zeroPad(data.getSeconds(), 2);
        dados[1].forEach( comissaoTour => {
            let i = dados[0].findIndex(element => element.guia.codigo === comissaoTour.guia.codigo);
            if (i === -1){
                dados[0].push({
                    guia: comissaoTour.guia,
                    comissaoTour: comissaoTour.comissaoTour,
                    comissaoLojaCentro: 0,
                    comissaoLojaTour: 0
                })
            } else {
                dados[0][i].comissaoTour = comissaoTour.comissaoTour
            }
        });
        let j = 0;
        const comissoes = await Promise.all(dados[0].map(async com => {
            if (!com.comissaoTour) com.comissaoTour = 0;
            let guia = await getGuiaPorCodigo(com.guia.codigo);
            if (!guia) console.log(`Guia ${com.guia.codigo} não encontrado.`)
            com.guia.id = guia._id;
            com.guia.tipoChavePix = guia.tipoChavePix;
            com.guia.chavePix = guia.chavePix;
            com.guia.cpfCnpjBeneficiario = guia.cpfCnpjBeneficiario;
            com.comissaoLojaTour = Number(com.comissaoLojaTour.toFixed(2));
            com.comissaoLojaCentro = Number(com.comissaoLojaCentro.toFixed(2));
            com.comissaoTotal = com.comissaoTour + com.comissaoLojaCentro + com.comissaoLojaTour;
            com.dataInicial = new Date(dataI);
            com.dataFinal = new Date(dataF);
            com.dataPagamento = data;
            com.pagamentoEfetivado = false;
            com.mensagem = "Etapa 1 de 3";
            com.identificador = dataHora + j;
            j++;
            return com;
        }));
        return comissoes;
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function obterComissoesSimplificadas(dataI, dataF){
    try {
        console.log("Ober comissões simplificadas");
        return [];
        // const comissoesPromessas = [getComissoesChef(dataI, dataF), getComissoesMaycon(dataI, dataF)];
        // const dados = await Promise.all(comissoesPromessas);
        // dados[1].map( comissaoTour => {
        //     let i = dados[0].findIndex(element => element.guia.codigo === comissaoTour.guia.codigo);
        //     if (i == -1){
        //         dados[0].push({
        //             guia: comissaoTour.guia,
        //             comissaoTour: comissaoTour.comissaoTour,
        //             comissaoLojaCentro: 0,
        //             comissaoLojaTour: 0
        //         })
        //     } else {
        //         dados[0][i].comissaoTour = comissaoTour.comissaoTour
        //     }
        // });
        // return dados[0];
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function salvarComissoes(comissoes){
    // TENTAR ALTERAR ESSA FORMA PARA CHAMAR A API 1 VEZ E ENVIAR ARRAY COM COMISSOES
    fetch("/api/comissoes", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + Cookies.get("token_tour")
        },
        body: JSON.stringify(comissoes)
    });
}

export async function enviarOcorrencias(identificador, ocor) {
    try {
        const resposta = await fetch(`/api/comissoes/ocorrencias/${identificador}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(ocor)
        })
        return resposta.status;
    } catch (e) {
        console.log(e.message);
    }
}

export async function editarComissao(identificador, atualizacao){
    try {
        fetch(`/api/comissoes/editar/${identificador}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(atualizacao)
        })
    } catch (e) {
        console.log(e.message);
    }
}

export async function getComissoesGuia(guia, pagina = 1, ordem = 1, ordenacao = "dataPagamento") {
    try {
        const resposta = await fetch(`/api/comissoes/guia/${guia}?pagina=${pagina}&ordem=${ordem}&ordenacao=${ordenacao}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function getComissoesSalvas(nomeGuia, dataPagamento, pagina = 1, ordenacao = "dataPagamento", ordem = 1, limite = 30, pago){
    try {
        let params = `limite=${limite}&ordenacao=${ordenacao}&ordem=${ordem}&pagina=${pagina}`
        if (nomeGuia) params += `&nomeGuia=${nomeGuia}`;
        if (dataPagamento) params += `&dataPagamento=${dataPagamento}`;
        if (pago) params += `&pagamentoEfetivado=${pago}`;
        const resposta = await fetch(`/api/comissoes/busca?${params}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
    }
}

export async function removerResiduos(){
    try {
        const resposta = await fetch(`/api/comissoes/deletar`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                mensagem: "Etapa 1 de 3"
            })
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return e.message;
    }
}